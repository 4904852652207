import * as constants from "./constants";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { token } from "./constants";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function PrintConsoleLog(text: any, prefix: string = "") {
  if (constants.DEBUG) {
    console.log(`${prefix}: `, text);
  }
}

// TODO decode encode

// TODO LocalStorage
// localStorage.setItem("key", "val")
// localStorage.getItem("key")
// localStorage.removeItem("key")

export function GetCookieValue(key: string) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Разбиваем куку на части по знаку "="
    const [cookieKey, cookieValue] = cookie.split("=");

    // Если ключ совпадает, возвращаем значение
    if (cookieKey === key) {
      return cookieValue;
    }
  }

  // Если ключ не найден, возвращаем null или другое значение по умолчанию
  return null;
}

export function SetCookieValue(
  key: string,
  value: string,
  // lifetimeSecond: number,
) {
  // const futureDate = new Date(new Date().getTime() + lifetimeSecond * 1000);

  // const cookie = `${key}=${value}; path=/; expires=${futureDate.toUTCString()}`;
  const cookie = `${key}=${value}; path=/`;

  document.cookie = cookie;

  // Если ключ не найден, возвращаем null или другое значение по умолчанию
  return null;
}

export function AuthGuard({ children }: any) {
  const token = useSelector((state: any) => state.token);
  const navigate = useNavigate();
  const location = useLocation();
  const tokenCookie = GetCookieValue("token");

  // useEffect(() => {
  //   if (token && token.data && token.data.access) {
  //     console.log("DONE");
  //     if (location.pathname === "/auth") {
  //       navigate("/");
  //     }
  //   } else {
  //     // TODO Можно проверять по UserDetail отправлять запрос в user/me (не записывать в кукисы)
  //     console.log("NOT DONE");
  //     navigate("/auth");
  //   }
  // }, [token.data]);

  useEffect(() => {
    if (tokenCookie) {
      if (location.pathname === "/auth") {
        navigate("/");
      }
    } else {
      // TODO Можно проверять по UserDetail отправлять запрос в user/me (не записывать в кукисы)
      navigate("/auth");
    }
  }, []);

  return <>{children}</>;
}

export function Modal1({ open, onClose, children }: any) {
  return (
    <div
      // onClick={onClose}
      onClick={(e) => e.stopPropagation()}
      className={`fixed inset-0 flex justify-center items-center transition-colors ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white rounded-xl shadow p-6 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 text-4lg"
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
}

export class LocalStorage {
  static get(key: string, isParse = true): any {
    const store: any = localStorage.getItem(key);
    if (isParse) {
      return store ? JSON.parse(store) : null;
    }
    return store ? store : null;
  }
  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  static remove(key: string) {
    localStorage.removeItem(key);
  }
}

export function notifySuccess(text: string) {
  toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function notifyError(text: string) {
  toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function notifyWarning(text: string) {
  toast.warning(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
