import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { constructorAction } from "../components/actions";
import { captchaCheck, loginData } from "../components/constants";
import * as constants from "../components/constants";
import Captcha from "../components/ui/captcha";
import * as utils from "../components/utils";
import { AuthGuard, GetCookieValue, SetCookieValue } from "../components/utils";
import Base from "../components/ui/base";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function postLoginData(
  constant: any,
  dispatch: any,
  url: string,
  method: string,
  queryParams: string | undefined = "",
  form: object | undefined = undefined,
  auth: boolean = true,
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      let config: any = {};
      if (auth) {
        const tokenCookie = utils.GetCookieValue("token");
        if (tokenCookie) {
          const tokenAccess = JSON.parse(
            utils.GetCookieValue("token") ?? "{}",
          ).access;
          config["Authorization"] = `Bearer ${tokenAccess}`;
        } else {
          config["Authorization"] = "";
        }
      }

      let response: any = undefined;

      switch (method.toLowerCase()) {
        case "get": {
          response = await axios.get(
            `${constants.API}${url}${queryParams}`,
            // @ts-ignore
            { headers: config },
          );
          break;
        }
        case "post": {
          response = await axios.post(
            `${constants.API}${url}`,
            form,
            // @ts-ignore
            { headers: config },
          );
          break;
        }
        default: {
          // todo raise exception
          // console.error("Unexpected method");
        }
      }
      if (response && response.data) {
        dispatch({
          type: constant.success,
          payload: response.data,
        });
      } else {
        dispatch({
          type: constant.error,
          payload: response.statusText,
        });
      }
    } catch (error: any) {
      // console.log("error", error);

      // TODO Нужно посмотреть есть ли в error data (При сторонней ошибки нет данных в error.response.data.error (Это у меня так))
      dispatch({
        type: constant.error,
        payload: error.response.data.error,
      });
      // console.error("error: ", error);
    }
  };
}

export default function Page() {
  const dispatch = useDispatch();
  const loginDataStore = useSelector((state: any) => state.loginDataStore);
  const captchaCheckStore = useSelector(
    (state: any) => state.captchaCheckStore,
  );

  const navigate = useNavigate();

  const [form, setForm] = useState({ username: "", password: "" });
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  async function sendData(event: any) {
    event.preventDefault();
    await postLoginData(
      constants.loginData,
      dispatch,
      "doctor_tools/api/token/",
      // "http://127.0.0.1:8000/doctor_tools/api/token/",
      "post",
      "",
      { ...form },
      false,
    )();
  }

  // useEffect(() => {
  //   console.log("loginDataStore: ", loginDataStore);
  // }, [loginDataStore]);

  useEffect(() => {
    if (loginDataStore.data) {
      utils.SetCookieValue(
        "token",
        JSON.stringify(loginDataStore.data.response.token),
        // 600,
      );
      dispatch({
        type: constants.token.success,
        payload: loginDataStore.data.response.token,
      });
    }

    const tokenCookie = utils.GetCookieValue("token");
    if (tokenCookie) {
      setLoggedIn(true);
    }
    dispatch({ type: constants.loginData.reset });
  }, [loginDataStore.data]);

  useEffect(() => {
    if (loggedIn) {
      return navigate("/");
    }
  }, [loggedIn]);

  return (
    // <Base>
    <div className="bg-gray-200 h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-semibold mb-4">Вход</h2>
        <div>
          <form onSubmit={sendData}>
            <div className="mb-4">
              {loginDataStore.error && (
                <p className="block text-red-600 text-sm font-medium mb-4">
                  {loginDataStore.error}
                </p>
              )}
              <label className="block text-gray-600 text-sm font-medium mb-2">
                Имя пользователя
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={form.username}
                onChange={(e) => setForm({ ...form, username: e.target.value })}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-medium mb-2">
                Пароль
              </label>
              <input
                // type="password"
                type={showPassword ? "text" : "password"}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
              <div className="mt-2">
                <input
                  type="checkbox"
                  onChange={() => {
                    setShowPassword(!showPassword);
                  }}
                />
                <span className="ml-2 text-gray-600 text-sm font-medium">
                  Показать пароль
                </span>
              </div>
            </div>

            {/*<div>*/}
            {/*  <Captcha />*/}
            {/*</div>*/}
            {/*{captchaCheckStore && captchaCheckStore.data && (*/}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Войти
            </button>
            {/*)}*/}
          </form>
        </div>
      </div>
    </div>
    // </Base>
  );
}
