import * as navbar from "./navbar";
import * as footer from "./footer";
import * as utils from "../utils";

export default function Base1({ children }: any) {
  return (
    <div>
      <utils.AuthGuard>
      <navbar.Navbar1 />
      <div className="min-h-full min-w-full text-gray-600">
        <main>{children}</main>
      </div>
      {/*<footer.Footer1 />*/}
      </utils.AuthGuard>
    </div>
  );
}
