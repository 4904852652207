import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

//
import * as reducers from "../components/reducers";
import * as constants from "../components/constants";
import * as utils from "../components/utils";
import {
  saveRiskFactorVersion,
  selectedRiskFactorVersionConst,
} from "../components/constants";

export const reducer = combineReducers({
  messageListStore: reducers.constructorReducer(constants.messageList),
  loginDataStore: reducers.constructorReducer(constants.loginData),
  token: reducers.constructorReducer(constants.token),
  captchaCheckStore: reducers.constructorReducer(constants.captchaCheck),
  questionsListStore: reducers.constructorReducer(constants.questionsList),
  testListStore: reducers.constructorReducer(constants.testList),
  riskFactorsListStore: reducers.constructorReducer(constants.riskFactorsList),
  saveSymptomVersionStore: reducers.constructorReducer(
    constants.saveSymptomVersion,
  ),
  saveRiskFactorVersionStore: reducers.constructorReducer(
    constants.saveRiskFactorVersion,
  ),
  symptomsVersionsListStore: reducers.constructorReducer(
    constants.symptomsVersionsList,
  ),
  selectedSymptomVersionStore: reducers.constructorReducer(
    constants.selectedSymptomVersionConst,
  ),
  selectedRiskFactorVersionStore: reducers.constructorReducer(
    constants.selectedRiskFactorVersionConst,
  ),
  riskFactorVersionsListStore: reducers.constructorReducer(
    constants.riskFactorVersionsList,
  ),
  questionnaireStructureList: reducers.constructorReducer(
    constants.questionnaireStructureList,
  ),
  // messageDetailStore: reducers.constructorReducer(constants.messageDetail),
  nowMessageListStore: reducers.constructorReducer(constants.nowMessageList),
  resultPageStatusStore: reducers.constructorReducer(
    constants.resultPageStatus,
  ),
  listOfSelectedSymptomsStore: reducers.constructorReducer(
    constants.listOfSelectedSymptoms,
  ),
  // userLoginStore: reducers.constructorReducer(constants.nowMessageList),
  storeUserRegister: reducers.constructorReducer(
    constants.constantUserRegister,
  ),

  // TODO NEW //////////////////////////////////////////////////////////////////
});

const tokenFromCookie = JSON.parse(utils.GetCookieValue("token") ?? "{}");
const stateQuestionnaireQuestionsStorage = localStorage.getItem(
  "stateQuestionnaireQuestions",
)
  ? // @ts-ignore
    JSON.parse(localStorage.getItem("stateQuestionnaireQuestions"))
  : null;
//     JSON.parse(
//   localStorage.getItem("stateQuestionnaireQuestions") ?? "{}",
// );
const stateQuestionnaireStructure = localStorage.getItem(
  "stateQuestionnaireStructure",
)
  ? // @ts-ignore
    JSON.parse(localStorage.getItem("stateQuestionnaireStructure"))
  : null;
//     JSON.parse(
//   localStorage.getItem("stateQuestionnaireStructure") ?? "{}",
// );
const stateRiskFactorList = localStorage.getItem("riskFactorsList")
  ? // @ts-ignore
    JSON.parse(localStorage.getItem("riskFactorsList"))
  : null;

const stateTestList = localStorage.getItem("testList")
  ? // @ts-ignore
    JSON.parse(localStorage.getItem("testList"))
  : null;

const preloadedState = {
  token: tokenFromCookie
    ? { load: false, data: tokenFromCookie }
    : { load: false },
  questionsListStore: stateQuestionnaireQuestionsStorage
    ? { load: false, data: { response: stateQuestionnaireQuestionsStorage } }
    : { load: false },
  questionnaireStructureList: stateQuestionnaireStructure
    ? {
        load: false,
        data: {
          response: stateQuestionnaireStructure,
        },
      }
    : { load: false },
  riskFactorsListStore: stateRiskFactorList
    ? { load: false, data: { response: stateRiskFactorList } }
    : { load: false },
  testListStore: stateTestList
    ? { load: false, data: { response: stateTestList } }
    : { load: false },
};

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  preloadedState: preloadedState,
});
