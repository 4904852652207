import {
  MenuItemForComputer,
  MenuItemForMobile,
} from "../components/ui/doctor_tools/menu_item";
import * as constants from "../components/constants";
import * as utils from "../components/utils";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { TECollapse } from "tw-elements-react";
import Base from "../components/ui/base";
import { useNavigate } from "react-router-dom";
import { resultPageStatus } from "../components/constants";
import * as actions from "../components/actions";

export async function postResultData(
  dispatch: any,
  access_token: string,
  form: object,
) {
  try {
    dispatch({ type: constants.resultPageStatus.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    // console.log("config", config);
    const response = await axios.post(
      `http://127.0.0.1:8000/doctor_tools/api/result_data/`,
      // @ts-ignore
      config,
      // { ...form },
      form,
    );
    // console.log("response: ", response);
    if (response.data) {
      dispatch({
        type: constants.resultPageStatus.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.resultPageStatus.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.resultPageStatus.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.resultPageStatus.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export default function Page() {
  const resultPageStatusStore = useSelector(
    (state: any) => state.resultPageStatusStore,
  );

  const dispatch = useDispatch();

  // const [state, setState]: any = useState({});
  const [stateResult, setStateResult]: any = useState([]);
  const [isOpen, setIsOpen]: any = useState(false);
  const [stateSelected, setStateSelected]: any = useState({
    title: "",
    index: null,
  });

  useEffect(() => {
    // sendData();
    if (resultPageStatusStore.data && resultPageStatusStore.data.response) {
      setStateResult(resultPageStatusStore.data.response);
    }
  }, [resultPageStatusStore]);

  useEffect(() => {
    const stateQuestionnaireQuestionsStorage = JSON.parse(
      localStorage.getItem("stateQuestionnaireQuestions") ?? "[]",
    );
    const stateRiskFactorsStorage = JSON.parse(
      localStorage.getItem("riskFactorsList") ?? "[]",
    );
    const stateTestListStorage = JSON.parse(
      localStorage.getItem("testList") ?? "[]",
    );
    // setState({
    //   symptoms: stateQuestionnaireQuestionsStorage,
    //   risk_factors: stateRiskFactorsStorage,
    // });

    // const tokenCookie = utils.GetCookieValue("token");
    // if (tokenCookie) {
    //   const tokenAccess = JSON.parse(
    //     utils.GetCookieValue("token") ?? "{}",
    //   ).access;
    //
    //   postResultData(dispatch, tokenAccess, {
    //     symptoms: stateQuestionnaireQuestionsStorage,
    //     risk_factors: stateRiskFactorsStorage,
    //     tests: stateTestListStorage,
    //   });
    // }
    actions.constructorAction(
      constants.resultPageStatus,
      dispatch,
      "doctor_tools/api/result_data/",
      // "http://127.0.0.1:8000/doctor_tools/api/post_test/",
      "post",
      "",
      {
        symptoms: stateQuestionnaireQuestionsStorage,
        risk_factors: stateRiskFactorsStorage,
        tests: stateTestListStorage,
      },
      true,
    )();
  }, []);

  const DropDownMenuItems = (props: any) => {
    return (
      <div
        // href="#"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        onClick={() => {
          setIsOpen(false);
          setStateSelected({ title: props.title, index: props.index });
        }}
      >
        {props.title}
      </div>
    );
  };

  return (
    <Base>
      {/*<div className="min-h-full">*/}
      <main>
        <div className="text-gray-600 mx-20 mt-4">
          <div className="flex items-left justify-left mb-8">
            <div className="relative inline-block text-left">
              {/*<div className="font-bold text-xl text-center mb-4">*/}
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                // className="p-4 w-full flex items-center justify-between font-bold rounded-lg tracking-wider border border-transparent border-black active:border-white duration-300 active:text-white"
                className="w-full flex items-center justify-between tracking-wider bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded-lg active:border-white duration-300 active:text-white"
              >
                {stateSelected.title === ""
                  ? "Выберите возраст"
                  : stateSelected.title}
                {isOpen ? (
                  <img
                    src="/static/dropdown-closed.svg"
                    alt="edit-button"
                    className="h-6 w-6 text-white rounded-md"
                  />
                ) : (
                  <img
                    src="/static/dropdown-opened.svg"
                    alt="edit-button"
                    className="h-6 w-6 text-white rounded-md"
                  />
                )}
              </button>

              {isOpen && (
                <div
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {stateResult &&
                    stateResult.length > 0 &&
                    stateResult.map((item0: any, index0: number) => (
                      <div className="py-1" role="none" key={index0}>
                        <DropDownMenuItems
                          title={`Возраст ${item0.age}`}
                          index={index0}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div>
            {stateResult &&
              stateResult.length > 0 &&
              stateSelected.title !== "" && (
                <div>
                  {/*<div className="font-bold text-xl text-center mb-4">*/}
                  {/*  {stateSelected.title}*/}
                  {/*</div>*/}
                  {/*<table className="min-w-full mb-24">*/}

                  {/*<table className="w-full text-sm text-left rtl:text-right text-gray-600 mb-24">*/}
                  {/*  <thead className="text-xs uppercase bg-gray-700 text-gray-400">*/}
                  {/*    <tr>*/}
                  {/*      <th className="border-b border-black p-2 text-center">*/}
                  {/*        /!*<th*!/*/}
                  {/*        /!*  scope="col"*!/*/}
                  {/*        /!*  className="px-6 py-3 border border-white"*!/*/}
                  {/*        /!*>*!/*/}
                  {/*        Заболевание*/}
                  {/*      </th>*/}
                  {/*      <th className="border-b border-black p-2 text-center">*/}
                  {/*        /!*<th*!/*/}
                  {/*        /!*  scope="col"*!/*/}
                  {/*        /!*  className="px-6 py-3 border border-white"*!/*/}
                  {/*        /!*>*!/*/}
                  {/*        Вероятность на симптомах*/}
                  {/*      </th>*/}
                  {/*      <th className="border-b border-black p-2 text-center">*/}
                  {/*        /!*<th*!/*/}
                  {/*        /!*  scope="col"*!/*/}
                  {/*        /!*  className="px-6 py-3 border border-white"*!/*/}
                  {/*        /!*>*!/*/}
                  {/*        Вероятность на Ф.Р.*/}
                  {/*      </th>*/}
                  {/*      <th className="border-b border-black p-2 text-center">*/}
                  {/*        /!*<th*!/*/}
                  {/*        /!*  scope="col"*!/*/}
                  {/*        /!*  className="px-6 py-3 border border-white"*!/*/}
                  {/*        /!*>*!/*/}
                  {/*        Финальная вероятность*/}
                  {/*      </th>*/}
                  {/*    </tr>*/}
                  {/*  </thead>*/}
                  {/*  <tbody>*/}
                  {/*    {stateResult[stateSelected.index].all_res.map(*/}
                  {/*      (item2: any, index2: number) => (*/}
                  {/*        <tr*/}
                  {/*          key={index2}*/}
                  {/*          // className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"*/}
                  {/*        >*/}
                  {/*          <td className="border-b border-black p-2 text-center">*/}
                  {/*            /!*<th*!/*/}
                  {/*            /!*  scope="row"*!/*/}
                  {/*            /!*  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"*!/*/}
                  {/*            /!*>*!/*/}
                  {/*            {item2.illness}*/}
                  {/*            /!*</th>*!/*/}
                  {/*          </td>*/}
                  {/*          <td className="border-b border-black p-2 text-center">*/}
                  {/*            {item2.symptom_point}%*/}
                  {/*          </td>*/}
                  {/*          <td className="border-b border-black p-2 text-center">*/}
                  {/*            {item2.risk_factor_point}%*/}
                  {/*          </td>*/}
                  {/*          <td className="border-b border-black p-2 text-center">*/}
                  {/*            {item2.finished_point}%*/}
                  {/*          </td>*/}
                  {/*        </tr>*/}
                  {/*      ),*/}
                  {/*    )}*/}
                  {/*  </tbody>*/}
                  {/*</table>*/}

                  <table className="w-full text-gray-600 mb-24">
                    <thead>
                      <tr>
                        <th className="border border-gray-500 p-2 text-center">
                          Заболевание
                        </th>
                        <th className="border border-gray-500 p-2 text-center">
                          Вероятность на Симптомах
                        </th>
                        <th className="border border-gray-500 p-2 text-center">
                          Вероятность на Ф.Р.
                        </th>
                        <th className="border border-gray-500 p-2 text-center">
                          Вероятность на Тестах
                        </th>
                        <th className="border border-gray-500 p-2 text-center">
                          Финальная вероятность
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stateResult[stateSelected.index].all_res.map(
                        (item2: any, index2: number) => (
                          <tr key={index2}>
                            <td className="border border-gray-500 p-2 text-center">
                              {item2.illness}
                            </td>
                            <td className="border border-gray-500 p-2 text-center">
                              {item2.symptom_point}%
                            </td>
                            <td className="border border-gray-500 p-2 text-center">
                              {item2.risk_factor_point}%
                            </td>
                            <td className="border border-gray-500 p-2 text-center">
                              {item2.test_point}%
                            </td>
                            <td className="border border-gray-500 p-2 text-center font-bold">
                              {item2.finished_point}%
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </div>
      </main>
      {/*</div>*/}
    </Base>
  );
}
