import * as constants from "../components/constants";
import * as actions from "../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Base from "../components/ui/base";
import * as utils from "../components/utils";
import LeftSymptomPanel from "./components/LeftSymptomPanel";
import { ToastContainer } from "react-toastify";

// TODO переписать с использованием actions.tsx
export async function getSymptomsVersions(dispatch: any, access_token: string) {
  try {
    dispatch({ type: constants.symptomsVersionsList.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.get(
      // "http://127.0.0.1:8000/doctor_tools/api/list_of_questions_of_questionnaire/",
      "http://127.0.0.1:8000/doctor_tools/api/list_of_symptoms_version/",
      // @ts-ignore
      // config,
    );
    if (response.data) {
      dispatch({
        type: constants.symptomsVersionsList.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.symptomsVersionsList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.symptomsVersionsList.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.symptomsVersionsList.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

// TODO переписать с использованием actions.tsx
export async function postSaveSymptomVersion(
  dispatch: any,
  access_token: string,
  form: object,
) {
  try {
    dispatch({ type: constants.saveSymptomVersion.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.post(
      `http://127.0.0.1:8000/doctor_tools/api/save_symptoms_version/`,
      // @ts-ignore
      // config,
      form,
      // { data: [...form] },
    );
    if (response.data) {
      dispatch({
        type: constants.saveSymptomVersion.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.saveSymptomVersion.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.saveSymptomVersion.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.saveSymptomVersion.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export default function Page() {
  const dispatch = useDispatch();

  const questionsListStore = useSelector(
    (state: any) => state.questionsListStore,
  );
  const saveSymptomVersionStore = useSelector(
    (state: any) => state.saveSymptomVersionStore,
  );
  const token = useSelector((state: any) => state.token);

  const [stateQuestionnaireQuestions, setStateQuestionnaireQuestions]: any =
    useState([]);
  const [stateFilteredData, setStateFilteredData]: any = useState([]);
  const [stateIllness, setStateIllness]: any = useState("Все");
  const [modalWindowOpen, setModalWindowOpen] = useState(false);

  function resetQuestionnaireQuestions() {
    localStorage.removeItem("stateQuestionnaireQuestions");
    // localStorage.removeItem("stateQuestionnaireStructure");
    setStateQuestionnaireQuestions({});
    dispatch({ type: constants.questionsList.reset });
    // dispatch({ type: constants.questionnaireStructureList.reset });
  }

  function saveSymptomsVersions() {
    const data = JSON.parse(
      localStorage.getItem("stateQuestionnaireQuestions") ?? "{}",
    );
    // postSaveSymptomVersion(dispatch, "", { data: data });
    actions.constructorAction(
      constants.saveSymptomVersion,
      dispatch,
      `doctor_tools/api/save_symptoms_version/`,
      // `http://127.0.0.1:8000/doctor_tools/api/save_symptoms_version/`,
      "post",
      "",
      { data: data },
      true,
    )();
    setModalWindowOpen(false);
  }

  function ConvertPagination(data: any, limit: number) {
    // const data1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const data1 = structuredClone(data);
    // const limit = 15;
    let globalArr = [];
    let localArr = [];

    for (let i = 0; i < data1.length; i++) {
      localArr.push(data1[i]);
      if (localArr.length >= limit) {
        globalArr.push(localArr);
        localArr = [];
      }
    }
    globalArr.push(localArr);
    return globalArr;
  }

  function filteredData(data: any, illness: string) {
    let filteredArray = [];
    if (illness === "Все") {
      return data;
    }
    let filteredDataList = structuredClone(data);
    for (let i = 0; i < filteredDataList.length; i++) {
      let filteredArrayBlock = [];
      for (
        let j = 0;
        j < filteredDataList[i].list_of_subblock_questions.length;
        j++
      ) {
        if (
          filteredDataList[i].list_of_subblock_questions[j].illness === illness
        ) {
          filteredDataList[i].list_of_subblock_questions[j].is_rowspan = true;
          filteredDataList[i].list_of_subblock_questions[j].rowspan = 1;
          filteredDataList[i].list_of_subblock_questions[j].is_hide = false;
          filteredArrayBlock.push(
            filteredDataList[i].list_of_subblock_questions[j],
          );
        } else {
          filteredDataList[i].list_of_subblock_questions[j].is_hide = true;
          filteredArrayBlock.push(
            filteredDataList[i].list_of_subblock_questions[j],
          );
        }
      }
      filteredArray.push({
        block_name: filteredDataList[i].block_name,
        block_numeration: filteredDataList[i].block_numeration,
        list_of_subblock_questions: filteredArrayBlock,
        subblock_name: filteredDataList[i].subblock_name,
        subblock_numeration: filteredDataList[i].subblock_numeration,
      });
    }
    return filteredArray;
  }

  useEffect(() => {
    const stateQuestionnaireQuestionsStorage = utils.LocalStorage.get(
      "stateQuestionnaireQuestions",
    );

    if (
      stateQuestionnaireQuestionsStorage &&
      Object.keys(stateQuestionnaireQuestionsStorage).length > 0
    ) {
      setStateQuestionnaireQuestions(
        structuredClone(stateQuestionnaireQuestionsStorage),
      );
    } else {
      if (questionsListStore.data && questionsListStore.data.response) {
        // TODO Слияние вот здесь
        setStateQuestionnaireQuestions(
          structuredClone(questionsListStore.data.response),
        );
      }
    }

    const stateIllnessStorage = utils.LocalStorage.get("stateSymptomIllness");

    if (stateIllnessStorage) {
      setStateIllness(structuredClone(stateIllnessStorage));
    }
  }, [questionsListStore]);

  useEffect(() => {
    if (
      stateQuestionnaireQuestions &&
      Object.keys(stateQuestionnaireQuestions).length > 0
    ) {
      utils.LocalStorage.set(
        "stateQuestionnaireQuestions",
        structuredClone(stateQuestionnaireQuestions),
      );
      let filteredListData = filteredData(
        stateQuestionnaireQuestions.data,
        stateIllness,
      );
      setStateFilteredData(structuredClone(filteredListData));
    }
  }, [stateQuestionnaireQuestions]);

  useEffect(() => {
    const stateIllnessStorage = utils.LocalStorage.get("stateSymptomIllness");

    if (
      stateIllness &&
      stateIllness !== "" &&
      stateIllness !== stateIllnessStorage
    ) {
      utils.LocalStorage.set(
        "stateSymptomIllness",
        structuredClone(stateIllness),
      );
    }

    const stateQuestionnaireQuestionsStorage = utils.LocalStorage.get(
      "stateQuestionnaireQuestions",
    );

    if (
      stateQuestionnaireQuestionsStorage &&
      stateQuestionnaireQuestionsStorage.data &&
      stateIllness
    ) {
      let filteredListData = filteredData(
        stateQuestionnaireQuestionsStorage.data,
        stateIllness,
      );
      setStateFilteredData(structuredClone(filteredListData));
    }
  }, [stateIllness]);

  useEffect(() => {
    if (saveSymptomVersionStore && saveSymptomVersionStore.data) {
      if (saveSymptomVersionStore.data.warning) {
        if (typeof saveSymptomVersionStore.data.warning === "string") {
          utils.notifyError(saveSymptomVersionStore.data.warning);
        } else {
          let text = "Внимание нарушены баллы:\n";
          for (
            let i = 0;
            i < saveSymptomVersionStore.data.warning.length;
            i++
          ) {
            text += `${
              Object.keys(saveSymptomVersionStore.data.warning[i])[0]
            } - ${Object.values(saveSymptomVersionStore.data.warning[i])[0]}\n`;
          }
          utils.notifyError(text);
        }
      }
      if (saveSymptomVersionStore.data.response) {
        utils.notifySuccess(saveSymptomVersionStore.data.response);
      }
      utils.LocalStorage.remove("userSelectedSymptomVersion");
      dispatch({ type: constants.symptomsVersionsList.reset });
      dispatch({ type: constants.saveSymptomVersion.reset });
    }
  }, [saveSymptomVersionStore]);

  const [isOpen, setIsOpen]: any = useState(false);

  const DropDownMenuItems = (props: any) => {
    return (
      <div
        // href="#"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        {props.title}
      </div>
    );
  };

  return (
    <Base>
      <main>
        <ToastContainer />
        {/*<ul className="flex flex-row">*/}
        {/*  {stateQuestionnaireQuestions &&*/}
        {/*    stateQuestionnaireQuestions.illnesses &&*/}
        {/*    ConvertPagination(stateQuestionnaireQuestions.illnesses).map(*/}
        {/*      (item0: any, index0: number) => (*/}
        {/*        <li className="border border-4 p-1">*/}
        {/*          <ul>*/}
        {/*            {item0.map((item1: any, index1: number) => (*/}
        {/*              <li>{item1}</li>*/}
        {/*            ))}*/}
        {/*          </ul>*/}
        {/*        </li>*/}
        {/*      ),*/}
        {/*    )}*/}
        {/*</ul>*/}
        <div className="flex h-screen text-gray-600">
          <div className="flex flex-col h-full w-72 p-4 ml-[12px] mr-[13px] border-r border-r-gray-300 border-solid">
            <LeftSymptomPanel />
          </div>
          <div className="flex-1 mx-2 mt-4 text-gray-600">
            {questionsListStore.load && (
              <div className="flex items-center justify-center h-screen text-2xl mb-8">
                Loading...
              </div>
            )}
            {questionsListStore.fail && (
              <div className="flex items-center justify-center h-screen text-2xl mb-8">
                Fail...
              </div>
            )}
            {questionsListStore.error && (
              <div className="flex items-center justify-center h-screen text-2xl mb-8">
                {questionsListStore.error}
              </div>
            )}
            {stateQuestionnaireQuestions.data &&
              stateQuestionnaireQuestions.data.length > 0 && (
                // <div className="flex flex-col w-48">
                <div className="mb-4">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded"
                    onClick={() => resetQuestionnaireQuestions()}
                  >
                    Сбросить вопросы
                  </button>
                  <button
                    onClick={() => setModalWindowOpen(true)}
                    className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded ml-4"
                  >
                    Сохранить изменения
                  </button>
                  {/*<utils.Modal isOpen={modalOpen} onClose={closeModal} />*/}
                  <utils.Modal1
                    open={modalWindowOpen}
                    onClose={() => setModalWindowOpen(false)}
                  >
                    <div className="text-center w-96">
                      <div className="mx-auto my-4 w-64">
                        <h3 className="text-lg font-black text-gray-800">
                          Сохранение изменений
                        </h3>
                        <p className="text-sm text-gray-500">
                          Вы уверены, что хотите сохранить изменения?
                        </p>
                      </div>
                      <div className="flex gap-4">
                        <button
                          className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded w-full"
                          onClick={() => {
                            saveSymptomsVersions();
                            // setTimeout(() => {
                            //   dispatch({
                            //     type: constants.symptomsVersionsList.reset,
                            //   });
                            // }, 2000);
                          }}
                        >
                          Сохранить
                        </button>
                        <button
                          className="btn btn-light w-full border border-gray-400"
                          onClick={() => setModalWindowOpen(false)}
                        >
                          Отменить
                        </button>
                      </div>
                    </div>
                  </utils.Modal1>
                  <div className="relative inline-block text-left ml-4">
                    {/*<div className="font-bold text-xl text-center mb-4">*/}
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      type="button"
                      // className="p-4 w-full flex items-center justify-between font-bold rounded-lg tracking-wider border border-transparent border-black active:border-white duration-300 active:text-white"
                      // className="bg-gray-200 hover:bg-gray-300 text-gray-600 text-center items-center rounded-md px-3 py-2 font-medium h-[44px]"
                      // className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                      className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                    >
                      {stateIllness === "Все"
                        ? "Выберите заболевание"
                        : stateIllness}
                      {isOpen ? (
                        <img
                          src="/static/dropdown-closed.svg"
                          alt="edit-button"
                          className="h-6 w-6 text-white rounded-md"
                        />
                      ) : (
                        <img
                          src="/static/dropdown-opened.svg"
                          alt="edit-button"
                          className="h-6 w-6 text-white rounded-md"
                        />
                      )}
                    </button>

                    {isOpen && (
                      <div
                        // className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {/*{false &&*/}
                        {/*  stateQuestionnaireQuestions &&*/}
                        {/*  stateQuestionnaireQuestions.illnesses &&*/}
                        {/*  stateQuestionnaireQuestions.illnesses.length > 0 &&*/}
                        {/*  // stateQuestionnaireQuestions.illnesses.map(*/}
                        {/*  ConvertPagination(*/}
                        {/*    stateQuestionnaireQuestions.illnesses,*/}
                        {/*  ).map((item0: any, index0: number) =>*/}
                        {/*    item0.map((item1: any, index1: number) => (*/}
                        {/*      <div*/}
                        {/*        className="py-1"*/}
                        {/*        role="none"*/}
                        {/*        key={index0}*/}
                        {/*        onClick={() => {*/}
                        {/*          setStateIllness(item0);*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <DropDownMenuItems title={item1} />*/}
                        {/*      </div>*/}
                        {/*    )),*/}
                        {/*  )}*/}
                        <ul className="flex flex-row">
                          {stateQuestionnaireQuestions &&
                            stateQuestionnaireQuestions.illnesses &&
                            stateQuestionnaireQuestions.illnesses.length > 0 &&
                            ConvertPagination(
                              stateQuestionnaireQuestions.illnesses,
                              14,
                            ).map((item0: any, index0: number) => (
                              <li key={index0}>
                                {/*<ul>*/}
                                {item0.map((item1: any, index1: number) => (
                                  // <li>
                                  <div
                                    className="py-1"
                                    role="none"
                                    key={index1}
                                    onClick={() => {
                                      setStateIllness(item1);
                                    }}
                                  >
                                    <DropDownMenuItems title={item1} />
                                  </div>
                                  // </li>
                                ))}
                                {/*</ul>*/}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                // </div>
              )}
            {stateQuestionnaireQuestions.data &&
            stateQuestionnaireQuestions.data.length > 0 &&
            stateFilteredData &&
            stateFilteredData.length > 0
              ? stateFilteredData.map((stateQuestion: any, index0: number) => (
                  <div key={index0}>
                    <p
                      className={
                        stateQuestion.subblock_name !== ""
                          ? "text-xl font-bold"
                          : "text-xl font-bold mb-4"
                      }
                    >
                      Block #{stateQuestion.block_numeration}.{" "}
                      {stateQuestion.block_name}
                    </p>
                    {stateQuestion.subblock_name !== "" && (
                      <p className="pl-2 text-xl font-bold mb-4">
                        Subblock #{stateQuestion.subblock_numeration}.{" "}
                        {stateQuestion.subblock_name}
                      </p>
                    )}
                    <div className="pl-2 pb-24">
                      <table className="min-w-full mb-24 text-gray-600">
                        <thead>
                          <tr>
                            <th
                              className="border border-gray-500 p-2 text-center"
                              colSpan={2}
                            >
                              №
                            </th>
                            {/*<th className="border p-2 text-center"></th>*/}
                            <th className="border border-gray-500 p-2 text-center">
                              Симптом
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              Порядок
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              Заболевание
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              Баллы
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              0-20
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              21-30
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              31-40
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              41-50
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              51-60
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              61-70
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              71+
                            </th>
                            <th className="border border-gray-500 p-2 text-center">
                              Шкала боли
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {stateQuestion.list_of_subblock_questions.map(
                            (item3: any, index3: number) =>
                              item3.is_hide ? (
                                ""
                              ) : (
                                <tr
                                  key={item3.table_id}
                                  className={
                                    item3.selected && item3.selected === true
                                      ? "bg-gray-200"
                                      : ""
                                  }
                                >
                                  {/*{item3.question_title !== null && (*/}
                                  {item3.is_rowspan && (
                                    <td
                                      className="border border-gray-500 p-2"
                                      rowSpan={item3.rowspan}
                                    >
                                      {item3.question_id}
                                    </td>
                                  )}

                                  {/*{item3.question_title !== null && (*/}
                                  {item3.is_rowspan && (
                                    <td
                                      className="border border-gray-500 p-2"
                                      rowSpan={item3.rowspan}
                                    >
                                      {item3.is_active ? (
                                        <input
                                          type="checkbox"
                                          defaultChecked={item3.selected}
                                          // value={item3.selected}
                                          onChange={() => {
                                            // let newArr = structuredClone(
                                            //   stateQuestionnaireQuestions[index0]
                                            //     .list_of_subblock_questions,
                                            // );
                                            let newMainArr1 = structuredClone(
                                              stateQuestionnaireQuestions,
                                            );
                                            let newMainArr = newMainArr1.data;

                                            const list_of_questions_numeration_connection =
                                              stateQuestion.list_of_questions_numeration_connection;

                                            let questionConnectionList = [];
                                            let questionConnectionSelectedBool =
                                              undefined;
                                            let objQuestionSecondGrade =
                                              undefined;

                                            if (
                                              list_of_questions_numeration_connection &&
                                              list_of_questions_numeration_connection.includes(
                                                item3.question_id,
                                              )
                                            ) {
                                              questionConnectionList =
                                                structuredClone(
                                                  stateQuestion
                                                    .dict_of_questions_connection[
                                                    item3.question_id
                                                  ],
                                                );
                                            }

                                            let newArr =
                                              newMainArr[index0]
                                                .list_of_subblock_questions;
                                            for (
                                              let i = 0;
                                              i < newArr.length;
                                              i++
                                            ) {
                                              if (
                                                newArr[i].question_id ===
                                                item3.question_id
                                              ) {
                                                newArr[i].selected =
                                                  !newArr[i].selected;
                                                questionConnectionSelectedBool =
                                                  newArr[i].selected;
                                                if (
                                                  newArr[
                                                    i
                                                  ].question_id.includes(".")
                                                ) {
                                                  let prefix =
                                                    newArr[i].question_id.split(
                                                      ".",
                                                    )[1];
                                                  let back_prefix = undefined;
                                                  if (prefix === "1") {
                                                    back_prefix = "2";
                                                  } else {
                                                    back_prefix = "1";
                                                  }
                                                  objQuestionSecondGrade = {
                                                    question_id:
                                                      newArr[
                                                        i
                                                      ].question_id.split(
                                                        ".",
                                                      )[0],
                                                    question_id_prefix: prefix,
                                                    question_id_back_prefix:
                                                      back_prefix,
                                                    bool: newArr[i].selected,
                                                  };
                                                  console.log(
                                                    "objQuestionSecondGrade",
                                                    objQuestionSecondGrade,
                                                  );
                                                  for (
                                                    let i = 0;
                                                    i < newArr.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      objQuestionSecondGrade &&
                                                      objQuestionSecondGrade.bool ===
                                                        true &&
                                                      newArr[i].question_id ===
                                                        `${objQuestionSecondGrade.question_id}.${objQuestionSecondGrade.question_id_back_prefix}`
                                                    ) {
                                                      newArr[i].is_active =
                                                        false;
                                                    }

                                                    if (
                                                      objQuestionSecondGrade &&
                                                      objQuestionSecondGrade.bool ===
                                                        false &&
                                                      newArr[i].question_id ===
                                                        `${objQuestionSecondGrade.question_id}.${objQuestionSecondGrade.question_id_back_prefix}`
                                                    ) {
                                                      newArr[i].is_active =
                                                        true;
                                                    }
                                                  }
                                                }

                                                // TODO
                                                // console.log(
                                                //   "newArr[i].selected",
                                                //   newArr[i].selected,
                                                // );
                                              }
                                              if (
                                                questionConnectionList.includes(
                                                  newArr[i].question_id,
                                                ) &&
                                                questionConnectionSelectedBool ===
                                                  true
                                              ) {
                                                newArr[i].is_active = true;
                                              }

                                              if (
                                                questionConnectionList.includes(
                                                  newArr[i].question_id,
                                                ) &&
                                                questionConnectionSelectedBool ===
                                                  false
                                              ) {
                                                newArr[i].is_active = false;
                                                newArr[i].selected = false;
                                              }
                                            }
                                            // stateQuestionnaireQuestions[
                                            //   index0
                                            // ].list_of_subblock_questions = newArr;
                                            // setStateQuestionnaireQuestions([
                                            //   ...stateQuestionnaireQuestions,
                                            // ]);
                                            setStateQuestionnaireQuestions({
                                              ...newMainArr1,
                                            });
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  )}

                                  {/*{item3.question_title !== null && (*/}
                                  {item3.is_rowspan && (
                                    <td
                                      className="border border-gray-500 p-2"
                                      rowSpan={item3.rowspan}
                                    >
                                      {item3.question_title}
                                    </td>
                                  )}

                                  {/*{item3.question_title !== null && (*/}
                                  {item3.is_rowspan && (
                                    <td
                                      className="border border-gray-500 p-2 text-center"
                                      rowSpan={item3.rowspan}
                                    >
                                      {item3.grade}
                                    </td>
                                  )}

                                  <td className="border border-gray-500 p-2">
                                    <div className="flex items-center justify-between">
                                      <span className="text-left w-11/12">
                                        {item3.illness}
                                      </span>
                                      {item3.edit && item3.edit === true ? (
                                        <button className="w-1/12">
                                          <img
                                            src="/static/save-button.svg"
                                            alt="edit-button"
                                            className="h-8 w-8 text-white rounded-md"
                                            onClick={() => {
                                              let newMainArr1 = structuredClone(
                                                stateQuestionnaireQuestions,
                                              );
                                              let newState = newMainArr1.data;
                                              newState[
                                                index0
                                              ].list_of_subblock_questions[
                                                index3
                                              ].edit =
                                                !newState[index0]
                                                  .list_of_subblock_questions[
                                                  index3
                                                ].edit;
                                              setStateQuestionnaireQuestions({
                                                ...newMainArr1,
                                              });
                                            }}
                                          />
                                        </button>
                                      ) : (
                                        <button className="w-1/12">
                                          <img
                                            src="/static/edit-button.svg"
                                            alt="edit-button"
                                            className="h-8 w-8 text-white rounded-md"
                                            onClick={() => {
                                              let newMainArr1 = structuredClone(
                                                stateQuestionnaireQuestions,
                                              );
                                              let newArr =
                                                newMainArr1.data[index0]
                                                  .list_of_subblock_questions;
                                              newArr[index3].edit =
                                                !newArr[index3].edit;
                                              newMainArr1.data[
                                                index0
                                              ].list_of_subblock_questions =
                                                newArr;
                                              setStateQuestionnaireQuestions({
                                                ...newMainArr1,
                                              });
                                            }}
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                  <td className="border border-gray-500 p-2 w-4">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.points}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].points = parseFloat(
                                            e.target.value,
                                          );
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.points
                                    )}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.first_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].first_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.first_illness_rate
                                    )}
                                    {/*{item3.first_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.second_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].second_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.second_illness_rate
                                    )}
                                    {/*{item3.second_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.third_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].third_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.third_illness_rate
                                    )}
                                    {/*{item3.third_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.fourth_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].fourth_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.fourth_illness_rate
                                    )}
                                    {/*{item3.fourth_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.fifth_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].fifth_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.fifth_illness_rate
                                    )}
                                    {/*{item3.fifth_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.sixth_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].sixth_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.sixth_illness_rate
                                    )}
                                    {/*{item3.sixth_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.edit && item3.edit === true ? (
                                      <input
                                        type="text"
                                        value={item3.seventh_illness_rate}
                                        className="w-16 h-8 p-2 border border-black rounded-md"
                                        // className="border border-black p-2"
                                        onChange={(e) => {
                                          let newMainArr1 = structuredClone(
                                            stateQuestionnaireQuestions,
                                          );
                                          let newArr =
                                            newMainArr1.data[index0]
                                              .list_of_subblock_questions;
                                          newArr[index3].seventh_illness_rate =
                                            parseFloat(e.target.value);
                                          newMainArr1.data[
                                            index0
                                          ].list_of_subblock_questions = newArr;
                                          setStateQuestionnaireQuestions({
                                            ...newMainArr1,
                                          });
                                        }}
                                      />
                                    ) : (
                                      item3.seventh_illness_rate
                                    )}
                                    {/*{item3.seventh_illness_rate}*/}
                                  </td>
                                  <td className="border border-gray-500 p-2">
                                    {item3.with_scale ? "Есть" : "Нет"}
                                  </td>
                                </tr>
                              ),
                            // </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
              : !questionsListStore.load && (
                  <div className="text-gray-600">Выберите Блоки и Подблоки</div>
                )}
          </div>
        </div>
      </main>
    </Base>
  );
}
