import { Link } from "react-router-dom";

interface MenuItemForComputerProps {
  title: string;
  path: string;
}

export function MenuItemForComputer(props: MenuItemForComputerProps) {
  return (
    <Link
      to={props.path}
      className="bg-gray-200 hover:bg-gray-300 text-gray-600 text-center items-center rounded-md px-3 py-2 font-medium h-[44px] mr-4"
      aria-current="page"
    >
      {props.title}
    </Link>
  );
}

export function MenuItemForMobile(props: MenuItemForComputerProps) {
  return (
    <a
      href={props.path}
      className="bg-gray-900 text-white block rounded-md px-3 py-2 text-xl font-medium"
      aria-current="page"
    >
      {props.title}
    </a>
  );
}
