export const DEBUG =
  window.location.host === "127.0.0.1:3000" ||
  window.location.host === "localhost:3000";

// export const API = DEBUG
//   ? "http://127.0.0.1:8000/"
//   : "https://api.mensense.kz/";

export const API = "https://api.mensense.kz/";

function constructorConstant(name: string) {
  return {
    load: `load_${name}`,
    success: `success_${name}`,
    fail: `fail_${name}`,
    error: `error_${name}`,
    reset: `reset_${name}`,
  };
}

export const messageList = constructorConstant("messageList");
// export const messageDetail = constructorConstant("messageDetail");
export const nowMessageList = constructorConstant("nowMessageList");
export const constantUserRegister = constructorConstant("constantUserRegister");
export const questionsList = constructorConstant("questionsList");
export const riskFactorsList = constructorConstant("riskFactorsList");
export const symptomsVersionsList = constructorConstant("symptomsVersionsList");
export const riskFactorVersionsList = constructorConstant(
  "riskFactorVersionsList",
);
export const testList = constructorConstant("testList");
export const saveSymptomVersion = constructorConstant("saveSymptomVersion");
export const saveRiskFactorVersion = constructorConstant(
  "saveRiskFactorVersion",
);
export const selectedSymptomVersionConst = constructorConstant(
  "selectedSymptomVersionConst",
);
export const selectedRiskFactorVersionConst = constructorConstant(
  "selectedRiskFactorVersionConst",
);
export const questionnaireStructureList = constructorConstant(
  "questionnaireStructureList",
);
export const loginData = constructorConstant("loginData");
export const captchaCheck = constructorConstant("captchaCheck");
export const token = constructorConstant("token");
export const resultPageStatus = constructorConstant("resultPageStatus");
export const listOfSelectedSymptoms = constructorConstant(
  "listOfSelectedSymptoms",
);

// TODO NEW //////////////////////////////////////////////////////////////////
