import {
  MenuItemForComputer,
  MenuItemForMobile,
} from "../components/ui/doctor_tools/menu_item";
import * as constants from "../components/constants";
import * as actions from "../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { TECollapse } from "tw-elements-react";
import Base from "../components/ui/base";
import { useNavigate } from "react-router-dom";
import { resultPageStatus, testList } from "../components/constants";
import { getRiskFactorList } from "./DoctorToolsRiskFactors";
import * as utils from "../components/utils";

export async function getTestList(dispatch: any, access_token: string) {
  try {
    dispatch({ type: constants.riskFactorsList.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    // console.log("config", config);
    const response = await axios.get(
      "http://127.0.0.1:8000/doctor_tools/api/list_of_tests/",
      // @ts-ignore
      // config,
    );
    // console.log("response: ", response);
    if (response.data) {
      dispatch({
        type: constants.testList.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.testList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.testList.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.testList.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export default function Page() {
  const dispatch = useDispatch();

  const testListStore = useSelector((state: any) => state.testListStore);

  const [state, setState]: any = useState([]);

  useEffect(() => {
    if (!testListStore.data && state.length === 0) {
      // getTestList(dispatch, "");
      actions.constructorAction(
        constants.testList,
        dispatch,
        "doctor_tools/api/list_of_tests/",
        // "http://127.0.0.1:8000/doctor_tools/api/list_of_tests/",
        "get",
        "",
        {},
        true,
      )();
    }
  }, []);

  useEffect(() => {
    // sendData();
    const data = utils.LocalStorage.get("testList");

    if (data && Object.keys(data).length > 0) {
      setState(structuredClone(data));
    } else {
      if (testListStore.data && testListStore.data.response) {
        // TODO Слияние вот здесь
        setState(structuredClone(testListStore.data.response));
      }
    }

    // console.log("testListStore =>", testListStore);
    // if (testListStore.data && testListStore.data.response) {
    //   setState(structuredClone(testListStore.data.response));
    // }
  }, [testListStore]);

  useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      utils.LocalStorage.set("testList", structuredClone(state));
    }

    // console.log("state: ", state);
    // if (state) {
    //   localStorage.setItem("testList", JSON.stringify(state));
    // }
  }, [state]);

  function setValue(value: string, maxValue: number, minValue: number) {
    // 1. Удалил все символы: minValue
    if (value.length === 0) {
      return minValue;
    }
    const newVal = parseInt(value, 10);

    // 2. Поставил знак не равный числу: minValue
    if (!newVal) {
      return minValue;
    }
    if (newVal >= maxValue) {
      return maxValue;
    }
    if (newVal <= minValue) {
      return minValue;
    }
    return value;
  }

  return (
    <Base>
      <div className="text-gray-600 mx-20 mt-4">
        {testListStore.load && (
          <div className="flex items-center justify-center h-screen text-2xl mb-8">
            Loading...
          </div>
        )}
        {testListStore.fail && (
          <div className="flex items-center justify-center h-screen text-2xl mb-8">
            Fail...
          </div>
        )}
        {testListStore.error && (
          <div className="flex items-center justify-center h-screen text-2xl mb-8">
            {testListStore.error}
          </div>
        )}
        {state && state.length > 0 && (
          <div>
            {/*<button*/}
            {/*  className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded mb-4"*/}
            {/*  // onClick={() => resetRiskFactorList()}*/}
            {/*>*/}
            {/*  Сбросить изменения*/}
            {/*</button>*/}

            <table className="w-full text-gray-600 mb-24">
              <thead>
                <tr>
                  <th className="border border-gray-500 p-2 text-center">№</th>
                  <th className="border border-gray-500 p-2 text-center">
                    Название теста
                  </th>
                  <th className="border border-gray-500 p-2 text-center">
                    Условия
                  </th>
                  <th className="border border-gray-500 p-2 text-center">
                    Баллы
                  </th>
                  <th className="border border-gray-500 p-2 text-center">
                    Max. балл
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="border border-gray-500 p-2 text-center">
                      {item.test_numeration}
                    </td>
                    <td className="border border-gray-500 p-2 text-center">
                      {item.test_name}
                    </td>
                    <td className="border border-gray-500 p-2 text-center">
                      {item.conditions.map((item1: any, index1: number) => (
                        <div key={index1} className="text-center">
                          <span className="font-bold">{item1.points}</span>
                          <span> - {item1.description}</span>
                        </div>
                      ))}
                    </td>
                    <td className="border border-gray-500 p-2 text-center">
                      {/*TODO Нужно сделать проверку на максимальный балл*/}
                      <input
                        type="number"
                        value={item.points}
                        min={0}
                        // max={item.max_points}
                        className="w-16 h-8 p-1 border border-gray-500 rounded-md"
                        // className="w-16 h-8 p-2 border border-black rounded-md"
                        // className="border border-black p-2"
                        onChange={(e) => {
                          let newArr = structuredClone(state);
                          newArr[index].points = setValue(
                            e.target.value,
                            item.max_points,
                            0,
                          );
                          setState([...newArr]);
                        }}
                      />
                    </td>
                    <td className="border border-gray-500 p-2 text-center">
                      {item.max_points}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Base>
  );
}
