import {
  MenuItemForComputer,
  MenuItemForMobile,
} from "./doctor_tools/menu_item";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../constants";
import * as actions from "../actions";
import * as utils from "../utils";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

// TODO переписать с использованием actions.tsx
export async function getSymptomsVersions(dispatch: any, access_token: string) {
  try {
    dispatch({ type: constants.symptomsVersionsList.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.get(
      // "http://127.0.0.1:8000/doctor_tools/api/list_of_questions_of_questionnaire/",
      "http://127.0.0.1:8000/doctor_tools/api/list_of_symptoms_version/",
      // "/doctor_tools/api/list_of_symptoms_version/",
      // @ts-ignore
      // config,
    );
    if (response.data) {
      dispatch({
        type: constants.symptomsVersionsList.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.symptomsVersionsList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.symptomsVersionsList.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.symptomsVersionsList.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

// TODO переписать с использованием actions.tsx
export async function getRiskFactorVersions(
  dispatch: any,
  access_token: string,
) {
  try {
    dispatch({ type: constants.riskFactorVersionsList.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.get(
      // "http://127.0.0.1:8000/doctor_tools/api/list_of_questions_of_questionnaire/",
      "http://127.0.0.1:8000/doctor_tools/api/list_of_risk_factors_version/",
      // "/doctor_tools/api/list_of_symptoms_version/",
      // @ts-ignore
      // config,
    );
    if (response.data) {
      dispatch({
        type: constants.riskFactorVersionsList.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.riskFactorVersionsList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.riskFactorVersionsList.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.riskFactorVersionsList.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export function Navbar1() {
  const [isOpen, setIsOpen]: any = useState(false);
  const [state, setState]: any = useState("");
  const [selectedSymptomVersion, setSelectedSymptomVersion]: any =
    useState(undefined);
  const [selectedRiskFactorVersion, setSelectedRiskFactorVersion]: any =
    useState(undefined);

  const dispatch = useDispatch();

  const symptomsVersionsListStore = useSelector(
    (state: any) => state.symptomsVersionsListStore,
  );
  const riskFactorVersionsListStore = useSelector(
    (state: any) => state.riskFactorVersionsListStore,
  );
  const location = useLocation();

  const [stateSymptomsVersionsList, setStateSymptomsVersionsList]: any =
    useState(undefined);
  const [stateRiskFactorVersionsList, setStateRiskFactorVersionsList]: any =
    useState(undefined);

  useEffect(() => {
    // console.log("symptomsVersionsListStore", symptomsVersionsListStore);
    if (location.pathname === "/symptoms") {
      if (!symptomsVersionsListStore.data && !symptomsVersionsListStore.load) {
        // getSymptomsVersions(dispatch, "");
        actions.constructorAction(
          constants.symptomsVersionsList,
          dispatch,
          "doctor_tools/api/list_of_symptoms_version/",
          // "http://127.0.0.1:8000/doctor_tools/api/list_of_symptoms_version/",
          "get",
          "",
          {},
          true,
        )();
      } else {
        if (symptomsVersionsListStore && symptomsVersionsListStore.data) {
          setStateSymptomsVersionsList(
            structuredClone(symptomsVersionsListStore.data.response),
          );
        }
      }
    } else {
      if (symptomsVersionsListStore && symptomsVersionsListStore.data) {
        dispatch({ type: constants.symptomsVersionsList.reset });
      }
    }
  }, [symptomsVersionsListStore]);

  // TODO Заменить на новые функции с LocalStorage
  useEffect(() => {
    if (location.pathname === "/symptoms") {
      if (stateSymptomsVersionsList && stateSymptomsVersionsList.length > 0) {
        const current = utils.LocalStorage.get("userSelectedSymptomVersion");

        if (current) {
          setSelectedSymptomVersion(structuredClone(current));
        } else {
          setSelectedSymptomVersion(
            structuredClone(
              stateSymptomsVersionsList[stateSymptomsVersionsList.length - 1],
            ),
          );
        }
      }
    }
  }, [stateSymptomsVersionsList]);

  useEffect(() => {
    if (location.pathname === "/symptoms") {
      if (selectedSymptomVersion) {
        utils.LocalStorage.set(
          "userSelectedSymptomVersion",
          structuredClone(selectedSymptomVersion),
        );
        dispatch({
          type: constants.selectedSymptomVersionConst.success,
          payload: selectedSymptomVersion,
        });
      }
    }
  }, [selectedSymptomVersion]);

  // TODO Проверка перед записью в LocalStorage ()
  // TODO НАЧАЛО RiskFactor

  useEffect(() => {
    if (location.pathname === "/risk_factors") {
      if (
        !riskFactorVersionsListStore.data &&
        !riskFactorVersionsListStore.load
      ) {
        // getRiskFactorVersions(dispatch, "");
        actions.constructorAction(
          constants.riskFactorVersionsList,
          dispatch,
          "doctor_tools/api/list_of_risk_factors_version/",
          // "http://127.0.0.1:8000/doctor_tools/api/list_of_risk_factors_version/",
          "get",
          "",
          {},
          true,
        )();
      } else {
        if (
          riskFactorVersionsListStore.data &&
          riskFactorVersionsListStore.data.response
        ) {
          setStateRiskFactorVersionsList(
            structuredClone(riskFactorVersionsListStore.data.response),
          );
        }
      }
    } else {
      if (riskFactorVersionsListStore && riskFactorVersionsListStore.data) {
        dispatch({ type: constants.riskFactorVersionsList.reset });
      }
    }
  }, [riskFactorVersionsListStore]);

  useEffect(() => {
    if (location.pathname === "/risk_factors") {
      if (
        stateRiskFactorVersionsList &&
        stateRiskFactorVersionsList.length > 0
      ) {
        const current = utils.LocalStorage.get("userSelectedRiskFactorVersion");

        if (current) {
          setSelectedRiskFactorVersion(structuredClone(current));
        } else {
          setSelectedRiskFactorVersion(
            structuredClone(
              stateRiskFactorVersionsList[
                stateRiskFactorVersionsList.length - 1
              ],
            ),
          );
        }
      }
    }
  }, [stateRiskFactorVersionsList]);

  useEffect(() => {
    if (location.pathname === "/risk_factors") {
      if (selectedRiskFactorVersion) {
        utils.LocalStorage.set(
          "userSelectedRiskFactorVersion",
          structuredClone(selectedRiskFactorVersion),
        );
        dispatch({
          type: constants.selectedRiskFactorVersionConst.success,
          payload: selectedRiskFactorVersion,
        });
      }
    }
  }, [selectedRiskFactorVersion]);

  const DropDownMenuItems = (props: any) => {
    return (
      <div
        // href="#"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        onClick={() => {
          setIsOpen(false);
          setState(props.title);
        }}
      >
        {props.title}
      </div>
    );
  };

  return (
    <nav className="bg-white">
      {/*<div className="h-[44px] my-[22px] bg-red-500">*/}
      {/*  <div className="mx-auto max-w-full px-4 border border-gray-700 border-solid sm:px-6 lg:px-8"></div>*/}
      {/*</div>*/}
      {/*<hr />*/}
      {/*</div>*/}
      <div className="my-[22px]">
        <div className="flex items-left justify-between">
          <div className="w-2/12">
            <div className="ml-8">
              <img
                className="items-center"
                src="/static/logo_full.svg"
                alt="logo_full.svg"
              />
            </div>
          </div>
          <div className="w-10/12">
            <div className="flex items-left justify-between">
              <div className="w-9/12">
                <MenuItemForComputer title={"Результаты"} path={"/"} />
                <MenuItemForComputer title={"Симптомы"} path={"/symptoms"} />
                <MenuItemForComputer
                  title={"Факторы риска"}
                  path={"/risk_factors"}
                />
                <MenuItemForComputer title={"Тесты"} path={"/tests"} />
              </div>
              <div className="items-left w-3/12">
                {location.pathname === "/symptoms" && (
                  <div className="relative inline-block text-left">
                    {/*<div className="font-bold text-xl text-center mb-4">*/}
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      type="button"
                      // className="p-4 w-full flex items-center justify-between font-bold rounded-lg tracking-wider border border-transparent border-black active:border-white duration-300 active:text-white"
                      // className="bg-gray-200 hover:bg-gray-300 text-gray-600 text-center items-center rounded-md px-3 py-2 font-medium h-[44px]"
                      // className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                      className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                    >
                      {symptomsVersionsListStore.load
                        ? "Loading..."
                        : state === ""
                          ? `v${
                              selectedSymptomVersion
                                ? selectedSymptomVersion.version
                                : "-"
                            }(s) - ${
                              selectedSymptomVersion
                                ? selectedSymptomVersion.created
                                : "-"
                            }`
                          : // "Выберите версию"
                            state}
                      {isOpen ? (
                        <img
                          src="/static/dropdown-closed.svg"
                          alt="edit-button"
                          className="h-6 w-6 text-white rounded-md"
                        />
                      ) : (
                        <img
                          src="/static/dropdown-opened.svg"
                          alt="edit-button"
                          className="h-6 w-6 text-white rounded-md"
                        />
                      )}
                    </button>

                    {isOpen && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {stateSymptomsVersionsList &&
                          stateSymptomsVersionsList.length > 0 &&
                          stateSymptomsVersionsList.map(
                            (item0: any, index0: number) => (
                              <div
                                className="py-1"
                                role="none"
                                key={index0}
                                onClick={() => {
                                  // changeSymptomVersion(item0);
                                  setSelectedSymptomVersion(
                                    structuredClone(item0),
                                  );
                                }}
                              >
                                <DropDownMenuItems
                                  title={`v${item0.version}(s) - ${item0.created}`}
                                />
                              </div>
                            ),
                          )}
                        {/*<div className="py-1" role="none">*/}
                        {/*  <DropDownMenuItems title={`1111`} />*/}
                        {/*</div>*/}
                      </div>
                    )}
                  </div>
                )}
                {location.pathname === "/risk_factors" && (
                  <div className="relative inline-block text-left">
                    {/*<div className="font-bold text-xl text-center mb-4">*/}
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      type="button"
                      // className="p-4 w-full flex items-center justify-between font-bold rounded-lg tracking-wider border border-transparent border-black active:border-white duration-300 active:text-white"
                      // className="bg-gray-200 hover:bg-gray-300 text-gray-600 text-center items-center rounded-md px-3 py-2 font-medium h-[44px]"
                      // className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                      className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                    >
                      {riskFactorVersionsListStore.load
                        ? "Loading..."
                        : state === ""
                          ? `v${
                              selectedRiskFactorVersion
                                ? selectedRiskFactorVersion.version
                                : "-"
                            }(rf) - ${
                              selectedRiskFactorVersion
                                ? selectedRiskFactorVersion.created
                                : "-"
                            }`
                          : // "Выберите версию"
                            state}
                      {isOpen ? (
                        <img
                          src="/static/dropdown-closed.svg"
                          alt="edit-button"
                          className="h-6 w-6 text-white rounded-md"
                        />
                      ) : (
                        <img
                          src="/static/dropdown-opened.svg"
                          alt="edit-button"
                          className="h-6 w-6 text-white rounded-md"
                        />
                      )}
                    </button>

                    {isOpen && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {stateRiskFactorVersionsList &&
                          stateRiskFactorVersionsList.length > 0 &&
                          stateRiskFactorVersionsList.map(
                            (item0: any, index0: number) => (
                              <div
                                className="py-1"
                                role="none"
                                key={index0}
                                onClick={() => {
                                  // changeRiskFactorVersion(item0);
                                  setSelectedRiskFactorVersion(
                                    structuredClone(item0),
                                  );
                                }}
                              >
                                <DropDownMenuItems
                                  title={`v${item0.version}(rf) - ${item0.created}`}
                                />
                              </div>
                            ),
                          )}
                        {/*<div className="py-1" role="none">*/}
                        {/*  <DropDownMenuItems title={`1111`} />*/}
                        {/*</div>*/}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/*<div className="max-w-full px-4 border-b border-b-gray-300 sm:px-6 lg:px-8">*/}
      {/*  <div className="flex items-center justify-between">*/}
      {/*    <div className="flex items-center">*/}
      {/*      <div className="hidden md:block">*/}
      {/*        /!*<div className="my-[22px] h-[44px] ml-[300px] xl:ml-[300px] lg:ml-[250px] flex items-stretch space-x-4">*!/*/}
      {/*        /!*<div className="my-[22px] h-[44px] xl:ml-[300px] lg:ml-[250px] flex justify-between items-center">*!/*/}
      {/*        <div className="my-[22px] h-[44px] flex justify-between items-center">*/}
      {/*          <img src="/static/logo_full.svg" alt="logo_full.svg" />*/}
      {/*          <div>*/}
      {/*            <MenuItemForComputer title={"Результаты"} path={"/"} />*/}
      {/*            <MenuItemForComputer title={"Симптомы"} path={"/symptoms"} />*/}
      {/*            <MenuItemForComputer*/}
      {/*              title={"Факторы риска"}*/}
      {/*              path={"/risk_factors"}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          */}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<hr />*/}
    </nav>
  );
}
