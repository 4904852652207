import * as actions from "../../components/actions";
import * as constants from "../../components/constants";
import * as utils from "../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { TECollapse } from "tw-elements-react";
import { Collapse } from "react-collapse";

export default function Panel() {
  const dispatch = useDispatch();

  const questionnaireStructureList = useSelector(
    (state: any) => state.questionnaireStructureList,
  );
  const selectedSymptomVersionStore = useSelector(
    (state: any) => state.selectedSymptomVersionStore,
  );
  const questionsListStore = useSelector(
    (state: any) => state.questionsListStore,
  );

  const [stateQuestionnaireStructure, setStateQuestionnaireStructure]: any =
    useState([]);

  function LoadData() {
    actions.constructorAction(
      constants.questionnaireStructureList,
      dispatch,
      "doctor_tools/api/list_of_structure_of_questionnaire/",
      // "http://127.0.0.1:8000/doctor_tools/api/list_of_structure_of_questionnaire/",
      "get",
      "",
      {},
      true,
    )();
  }

  // TODO Любой веб запрос нужно запускать только в случае завершения предыдущего запроса (нужно вставить проверку на .load === false)
  function sendDataToGetQuestionnaireQuestions() {
    let sendData = [];
    for (let i = 0; i < stateQuestionnaireStructure.length; i++) {
      if (
        stateQuestionnaireStructure[i].choosen &&
        stateQuestionnaireStructure[i].choosen === true
      ) {
        sendData.push(stateQuestionnaireStructure[i].id);
      }
      for (let j = 0; j < stateQuestionnaireStructure[i].items.length; j++) {
        if (
          stateQuestionnaireStructure[i].items[j].choosen &&
          stateQuestionnaireStructure[i].items[j].choosen === true
        ) {
          sendData.push(stateQuestionnaireStructure[i].items[j].id);
        }
      }
    }

    const version = utils.LocalStorage.get("userSelectedSymptomVersion");
    utils.LocalStorage.remove("stateQuestionnaireQuestions");

    if (version) {
      // TODO Новые данные пустые, нужно их слить со старыми измененными
      actions.constructorAction(
        constants.questionsList,
        dispatch,
        "doctor_tools/api/get_question_list_of_block/",
        // "http://127.0.0.1:8000/doctor_tools/api/get_question_list_of_block/",
        "post",
        "",
        {
          data: sendData,
          version: version,
        },
        true,
      )();
    }
  }

  useEffect(() => {
    const data = utils.LocalStorage.get("stateQuestionnaireStructure");
    if (data && data.length > 0) {
      setStateQuestionnaireStructure(structuredClone(data));
    } else {
      if (questionnaireStructureList && questionnaireStructureList.data) {
        setStateQuestionnaireStructure(
          structuredClone(questionnaireStructureList.data.response),
        );
      } else {
        if (!questionnaireStructureList.load) {
          LoadData();
        }
      }
    }
  }, [questionnaireStructureList]);

  useEffect(() => {
    // TODO Пофиксить изменение localstorage даже если он одинаковый (при первом входе будет меняться)
    if (stateQuestionnaireStructure && stateQuestionnaireStructure.length > 0) {
      utils.LocalStorage.set(
        "stateQuestionnaireStructure",
        structuredClone(stateQuestionnaireStructure),
      );
    }
  }, [stateQuestionnaireStructure]);

  useEffect(() => {
    const version = utils.LocalStorage.get("userSelectedSymptomVersion");
    const stateQuestionnaireQuestionsStorage = utils.LocalStorage.get(
      "stateQuestionnaireQuestions",
    );
    if (
      stateQuestionnaireQuestionsStorage &&
      stateQuestionnaireQuestionsStorage.version &&
      stateQuestionnaireQuestionsStorage.version !== version.version
    ) {
      if (
        version &&
        selectedSymptomVersionStore.data !== "" &&
        !questionsListStore.load
      ) {
        sendDataToGetQuestionnaireQuestions();
      }
    }
    // else {
    //   if (!stateQuestionnaireQuestionsStorage) {
    //     LoadData();
    //   }
    // }
  }, [selectedSymptomVersionStore]);

  return (
    <div>
      {stateQuestionnaireStructure && stateQuestionnaireStructure.length > 0 ? (
        stateQuestionnaireStructure.map((item: any, index: number) => (
          <div key={index} className="my-1">
            <div className="flex justify-between py-2">
              <span
                className={
                  item.choosen && item.choosen === true
                    ? "bg-blue-500 text-white font-bold"
                    : "hover:bg-blue-200 font-bold"
                }
                onClick={() => {
                  // TODO Производиться выбор подблоков - блоков и их установка назад в state
                  let newArr = structuredClone(stateQuestionnaireStructure);
                  newArr[index].choosen = !newArr[index].choosen;
                  newArr[index].collapse = newArr[index].choosen;
                  let newItems = structuredClone(
                    stateQuestionnaireStructure[index].items,
                  );
                  if (newItems && newItems.length > 0) {
                    for (let i = 0; i < newItems.length; i++) {
                      newItems[i].choosen = newArr[index].choosen;
                    }
                  }

                  newArr[index].items = newItems;
                  setStateQuestionnaireStructure(newArr);
                }}
              >
                {item.name}
              </span>

              {item.items && item.items.length > 0 && (
                <button
                  className="text-center text-3xl"
                  onClick={() => {
                    let newState = structuredClone(stateQuestionnaireStructure);
                    newState[index].collapse = !newState[index].collapse;
                    setStateQuestionnaireStructure([...newState]);
                  }}
                >
                  {item.collapse && item.collapse === true ? (
                    // {stateCollapseBlocks.includes(item.id) ? (
                    <span>-</span>
                  ) : (
                    <span>+</span>
                  )}
                </button>
              )}
            </div>

            {/*<TECollapse*/}
            {/*  show={item.collapse && item.collapse === true}*/}
            {/*  className="!mt-1 !mb-1"*/}
            {/*>*/}
            <Collapse
              isOpened={item.collapse && item.collapse === true}
              className="!mt-1 !mb-1"
            >
              <div className="border-b border-b-gray-300 border-solid">
                {item.items.map((item2: any, index2: number) => (
                  <div
                    key={index2}
                    className={
                      item2.choosen && item2.choosen === true
                        ? "pl-4 bg-blue-500 text-white py-2"
                        : "pl-4 hover:bg-blue-200 py-2"
                    }
                    onClick={() => {
                      let newArr = structuredClone(
                        stateQuestionnaireStructure[index],
                      );
                      let newItems = structuredClone(
                        stateQuestionnaireStructure[index].items,
                      );
                      newItems[index2].choosen = !newItems[index2].choosen;
                      let boolAllTrue = true;
                      if (newItems[index2].choosen === false) {
                        newArr.choosen = false;
                      } else if (newItems[index2].choosen === true) {
                        for (let i = 0; i < newItems.length; i++) {
                          if (
                            newItems[i].choosen === false ||
                            newItems[i].choosen === undefined
                          ) {
                            boolAllTrue = false;
                          }
                        }
                        if (boolAllTrue === true) {
                          newArr.choosen = true;
                        }
                      }

                      stateQuestionnaireStructure[index] = newArr;
                      stateQuestionnaireStructure[index].items = newItems;
                      setStateQuestionnaireStructure([
                        ...stateQuestionnaireStructure,
                      ]);
                    }}
                  >
                    {item2.subblock}
                  </div>
                ))}
              </div>
            </Collapse>
            {/*</TECollapse>*/}
          </div>
        ))
      ) : (
        <div>Данных нет!</div>
      )}
      <div className="border-t border-t-gray-300 border-solid">
        <div className="mt-12">
          <button
            onClick={() => sendDataToGetQuestionnaireQuestions()}
            className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded mb-24"
          >
            Обновить опросник
          </button>
        </div>
      </div>
    </div>
  );
}
