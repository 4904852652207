import * as constants from "../components/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { TECollapse } from "tw-elements-react";
import Base from "../components/ui/base";
import { useNavigate } from "react-router-dom";
import { resultPageStatus } from "../components/constants";
import * as utils from "../components/utils";
import * as actions from "../components/actions";
import { ToastContainer } from "react-toastify";

export async function getRiskFactorList(
  dispatch: any,
  access_token: string,
  form: object,
) {
  try {
    dispatch({ type: constants.riskFactorsList.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.post(
      "http://127.0.0.1:8000/doctor_tools/api/list_of_risk_factors/",
      // @ts-ignore
      // config,
      form,
    );
    if (response.data) {
      dispatch({
        type: constants.riskFactorsList.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.riskFactorsList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.riskFactorsList.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.riskFactorsList.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export async function postSaveSymptomVersion(
  dispatch: any,
  access_token: string,
  form: object,
) {
  try {
    dispatch({ type: constants.saveRiskFactorVersion.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.post(
      `http://127.0.0.1:8000/doctor_tools/api/save_risk_factor_version/`,
      // @ts-ignore
      // config,
      form,
      // { data: [...form] },
    );
    if (response.data) {
      dispatch({
        type: constants.saveRiskFactorVersion.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.saveRiskFactorVersion.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.saveRiskFactorVersion.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.saveRiskFactorVersion.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export default function Page() {
  const dispatch = useDispatch();

  const riskFactorsListStore = useSelector(
    (state: any) => state.riskFactorsListStore,
  );
  const selectedRiskFactorVersionStore = useSelector(
    (state: any) => state.selectedRiskFactorVersionStore,
  );
  const saveRiskFactorVersionStore = useSelector(
    (state: any) => state.saveRiskFactorVersionStore,
  );

  const [stateRiskFactorList, setStateRiskFactorList]: any = useState([]);
  const [stateIllness, setStateIllness]: any = useState("Все");
  const [stateFilteredData, setStateFilteredData]: any = useState([]);

  const [modalWindowOpen, setModalWindowOpen] = useState(false);

  function resetRiskFactorList() {
    localStorage.removeItem("riskFactorsList");
    setStateRiskFactorList([]);
    dispatch({ type: constants.riskFactorsList.reset });
    const selectedRiskFactorVersion = utils.LocalStorage.get(
      "userSelectedRiskFactorVersion",
    );
    // getRiskFactorList(dispatch, "", { version: selectedRiskFactorVersion });
    actions.constructorAction(
      constants.riskFactorsList,
      dispatch,
      "doctor_tools/api/list_of_risk_factors/",
      // "http://127.0.0.1:8000/doctor_tools/api/list_of_risk_factors/",
      "post",
      "",
      { version: selectedRiskFactorVersion },
      true,
    )();
  }

  function LoadData() {
    const selectedRiskFactorVersion = JSON.parse(
      localStorage.getItem("userSelectedRiskFactorVersion") ?? "{}",
    );
    // getRiskFactorList(dispatch, "", { version: selectedRiskFactorVersion });
    actions.constructorAction(
      constants.riskFactorsList,
      dispatch,
      "doctor_tools/api/list_of_risk_factors/",
      // "http://127.0.0.1:8000/doctor_tools/api/list_of_risk_factors/",
      "post",
      "",
      { version: selectedRiskFactorVersion },
      true,
    )();
  }

  function saveRiskFactorVersions() {
    const data = JSON.parse(localStorage.getItem("riskFactorsList") ?? "[]");
    // postSaveSymptomVersion(dispatch, "", { data: data });
    actions.constructorAction(
      constants.saveRiskFactorVersion,
      dispatch,
      "doctor_tools/api/save_risk_factor_version/",
      // "http://127.0.0.1:8000/doctor_tools/api/save_risk_factor_version/",
      "post",
      "",
      { data: data },
      true,
    )();
    setModalWindowOpen(false);
  }

  function ConvertPagination(data: any, limit: number) {
    // const data1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const data1 = structuredClone(data);
    // const limit = 15;
    let globalArr = [];
    let localArr = [];

    for (let i = 0; i < data1.length; i++) {
      localArr.push(data1[i]);
      if (localArr.length >= limit) {
        globalArr.push(localArr);
        localArr = [];
      }
    }
    globalArr.push(localArr);
    return globalArr;
  }

  function filteredData(data: any, illness: string) {
    let filteredArray = [];
    if (illness === "Все") {
      return data;
    }

    let filteredDataList = structuredClone(data);

    for (let i = 0; i < filteredDataList.length; i++) {
      if (filteredDataList[i].illness === illness) {
        filteredDataList[i].is_rowspan = true;
        filteredDataList[i].rowspan = 1;
        filteredDataList[i].is_hide = false;
        filteredArray.push(filteredDataList[i]);
      } else {
        filteredDataList[i].is_hide = true;
        filteredArray.push(filteredDataList[i]);
      }
    }
    return filteredArray;
  }

  useEffect(() => {
    const data = utils.LocalStorage.get("riskFactorsList");

    if (data && Object.keys(data).length > 0) {
      setStateRiskFactorList(structuredClone(data));
    } else {
      if (riskFactorsListStore.data && riskFactorsListStore.data.response) {
        // TODO Слияние вот здесь
        setStateRiskFactorList(
          structuredClone(riskFactorsListStore.data.response),
        );
      } else {
        if (!riskFactorsListStore.load) {
          setTimeout(() => {
            LoadData();
          }, 50);
        }
      }
    }

    const stateIllnessStorage = utils.LocalStorage.get(
      "stateRiskFactorIllness",
    );

    if (stateIllnessStorage) {
      setStateIllness(structuredClone(stateIllnessStorage));
    }
  }, [riskFactorsListStore]);

  useEffect(() => {
    if (stateRiskFactorList && Object.keys(stateRiskFactorList).length > 0) {
      utils.LocalStorage.set(
        "riskFactorsList",
        structuredClone(stateRiskFactorList),
      );
      let filteredListData = filteredData(
        stateRiskFactorList.data,
        stateIllness,
      );
      setStateFilteredData(structuredClone(filteredListData));
    }
  }, [stateRiskFactorList]);

  useEffect(() => {
    const stateIllnessStorage = utils.LocalStorage.get(
      "stateRiskFactorIllness",
    );

    if (
      stateIllness &&
      stateIllness !== "" &&
      stateIllness !== stateIllnessStorage
    ) {
      utils.LocalStorage.set(
        "stateRiskFactorIllness",
        structuredClone(stateIllness),
      );
    }

    const riskFactorsListStorage = utils.LocalStorage.get("riskFactorsList");

    if (riskFactorsListStorage && riskFactorsListStorage.data && stateIllness) {
      let filteredListData = filteredData(
        riskFactorsListStorage.data,
        stateIllness,
      );
      setStateFilteredData(structuredClone(filteredListData));
    }
  }, [stateIllness]);

  useEffect(() => {
    // console.log(
    //   "selectedRiskFactorVersionStore",
    //   selectedRiskFactorVersionStore,
    // );
    // console.log("riskFactorsListStore", riskFactorsListStore);
    // console.log(
    //   "selectedRiskFactorVersionStore.data",
    //   selectedRiskFactorVersionStore.data,
    // );
    const version = utils.LocalStorage.get("userSelectedRiskFactorVersion");
    const riskFactorsListStorage = utils.LocalStorage.get("riskFactorsList");
    if (
      riskFactorsListStorage &&
      riskFactorsListStorage.version &&
      riskFactorsListStorage.version !== version.version
    ) {
      if (
        version &&
        selectedRiskFactorVersionStore.data !== "" &&
        !riskFactorsListStore.load
      ) {
        // setStateRiskFactorList([]);
        utils.LocalStorage.remove("riskFactorsList");
        LoadData();
      }
    }
    // else {
    //   // setTimeout(() => {
    //   if (!riskFactorsListStorage) {
    //     LoadData();
    //   }
    //   // }, 50);
    // }
  }, [selectedRiskFactorVersionStore]);

  useEffect(() => {
    if (saveRiskFactorVersionStore && saveRiskFactorVersionStore.data) {
      if (saveRiskFactorVersionStore.data.warning) {
        if (typeof saveRiskFactorVersionStore.data.warning === "string") {
          utils.notifyError(saveRiskFactorVersionStore.data.warning);
        } else {
          let text = "Внимание нарушены баллы:\n";
          for (
            let i = 0;
            i < saveRiskFactorVersionStore.data.warning.length;
            i++
          ) {
            text += `${
              Object.keys(saveRiskFactorVersionStore.data.warning[i])[0]
            } - ${
              Object.values(saveRiskFactorVersionStore.data.warning[i])[0]
            }\n`;
          }
          utils.notifyError(text);
        }
      }
      if (saveRiskFactorVersionStore.data.response) {
        utils.notifySuccess(saveRiskFactorVersionStore.data.response);
      }
      utils.LocalStorage.remove("userSelectedRiskFactorVersion");
      dispatch({ type: constants.riskFactorVersionsList.reset });
      dispatch({ type: constants.saveRiskFactorVersion.reset });
    }
  }, [saveRiskFactorVersionStore]);

  const [isOpen, setIsOpen]: any = useState(false);

  const DropDownMenuItems = (props: any) => {
    return (
      <div
        // href="#"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        {props.title}
      </div>
    );
  };

  return (
    <Base>
      <main>
        <ToastContainer />
        <div className="text-gray-600 mx-20 mt-4">
          {riskFactorsListStore.load && (
            <div className="flex items-center justify-center h-screen text-2xl mb-8">
              Loading...
            </div>
          )}
          {riskFactorsListStore.fail && (
            <div className="flex items-center justify-center h-screen text-2xl mb-8">
              Fail...
            </div>
          )}
          {riskFactorsListStore.error && (
            <div className="flex items-center justify-center h-screen text-2xl mb-8">
              {riskFactorsListStore.error}
            </div>
          )}
          {stateRiskFactorList.data && stateRiskFactorList.data.length > 0 && (
            <div>
              <div>
                <button
                  className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded mb-4"
                  onClick={() => resetRiskFactorList()}
                >
                  Сбросить изменения
                </button>

                <button
                  onClick={() => setModalWindowOpen(true)}
                  className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded ml-4"
                >
                  Сохранить изменения
                </button>
                {/*<utils.Modal isOpen={modalOpen} onClose={closeModal} />*/}
                <utils.Modal1
                  open={modalWindowOpen}
                  onClose={() => setModalWindowOpen(false)}
                >
                  <div className="text-center w-96">
                    <div className="mx-auto my-4 w-64">
                      <h3 className="text-lg font-black text-gray-800">
                        Сохранение изменений
                      </h3>
                      <p className="text-sm text-gray-500">
                        Вы уверены, что хотите сохранить изменения?
                      </p>
                    </div>
                    <div className="flex gap-4">
                      <button
                        className="bg-blue-500 hover:bg-blue-600 border border-blue-800 text-white px-4 py-2 rounded w-full"
                        onClick={saveRiskFactorVersions}
                      >
                        Сохранить
                      </button>
                      <button
                        className="btn btn-light w-full border border-gray-400"
                        onClick={() => setModalWindowOpen(false)}
                      >
                        Отменить
                      </button>
                    </div>
                  </div>
                </utils.Modal1>
                <div className="relative inline-block text-left ml-4">
                  {/*<div className="font-bold text-xl text-center mb-4">*/}
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    // className="p-4 w-full flex items-center justify-between font-bold rounded-lg tracking-wider border border-transparent border-black active:border-white duration-300 active:text-white"
                    // className="bg-gray-200 hover:bg-gray-300 text-gray-600 text-center items-center rounded-md px-3 py-2 font-medium h-[44px]"
                    // className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                    className="w-full flex items-center justify-between tracking-wider bg-gray-200 hover:bg-gray-300 border border-gray-400 text-gray-600 font-medium px-3 py-2 rounded-lg active:border-white duration-300 active:text-white"
                  >
                    {stateIllness === "Все"
                      ? "Выберите заболевание"
                      : stateIllness}
                    {isOpen ? (
                      <img
                        src="/static/dropdown-closed.svg"
                        alt="edit-button"
                        className="h-6 w-6 text-white rounded-md"
                      />
                    ) : (
                      <img
                        src="/static/dropdown-opened.svg"
                        alt="edit-button"
                        className="h-6 w-6 text-white rounded-md"
                      />
                    )}
                  </button>

                  {isOpen && (
                    <div
                      // className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <ul className="flex flex-row">
                        {stateRiskFactorList &&
                          stateRiskFactorList.illnesses &&
                          stateRiskFactorList.illnesses.length > 0 &&
                          ConvertPagination(
                            stateRiskFactorList.illnesses,
                            14,
                          ).map((item0: any, index0: number) => (
                            <li key={index0}>
                              {item0.map((item1: any, index1: number) => (
                                <div
                                  className="py-1"
                                  role="none"
                                  key={index1}
                                  onClick={() => {
                                    setStateIllness(item1);
                                  }}
                                >
                                  <DropDownMenuItems title={item1} />
                                </div>
                              ))}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <table className="w-full text-gray-600 mb-24">
                <thead>
                  <tr>
                    <th className="border border-gray-500 p-2 text-center">
                      №
                    </th>
                    <th
                      className="border border-gray-500 p-2 text-center"
                      colSpan={2}
                    >
                      Фактор риска
                    </th>
                    <th className="border border-gray-500 p-2 text-center">
                      Заболевание
                    </th>
                    <th className="border border-gray-500 p-2 text-center">
                      Баллы
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/*{stateRiskFactorList.data.map((item: any, index: number) => (*/}
                  {stateFilteredData.map((item: any, index: number) =>
                    item.is_hide ? (
                      ""
                    ) : (
                      <tr
                        key={index}
                        className={
                          item.selected && item.selected === true
                            ? "bg-gray-200"
                            : ""
                        }
                      >
                        {/*{item.risk_factor !== null && (*/}
                        {item.is_rowspan && (
                          <td
                            className="border border-gray-500 p-2 text-center"
                            rowSpan={item.rowspan}
                          >
                            {item.risk_factor_num}
                          </td>
                        )}
                        {/*{item.risk_factor !== null && (*/}
                        {item.is_rowspan && (
                          <td
                            className="border border-gray-500 p-2 text-center"
                            rowSpan={item.rowspan}
                          >
                            {item.active_bool && (
                              <input
                                type="checkbox"
                                // defaultChecked={inputDefaultChecked(
                                //   item.selected,
                                // )}
                                defaultChecked={item.selected}
                                onChange={() => {
                                  // console.log(
                                  //   "item.selected",
                                  //   inputDefaultChecked(item.selected),
                                  // );
                                  let newMainArr =
                                    structuredClone(stateRiskFactorList);
                                  let newArr = newMainArr.data;
                                  for (let i = 0; i < newArr.length; i++) {
                                    if (
                                      newArr[i].risk_factor_num ===
                                      item.risk_factor_num
                                    ) {
                                      newArr[i].selected = !newArr[i].selected;
                                    }
                                  }
                                  setStateRiskFactorList({ ...newMainArr });
                                  // setStateRiskFactorList([...newArr]);
                                }}
                              />
                            )}
                          </td>
                        )}
                        {/*{item.risk_factor !== null && (*/}
                        {item.is_rowspan && (
                          <td
                            className="border border-gray-500 p-2 text-center"
                            rowSpan={item.rowspan}
                          >
                            {item.risk_factor}
                          </td>
                        )}
                        <td className="border border-gray-500 p-2 text-center">
                          {/*<div className="grid grid-rows-4 grid-flow-col gap-4">*/}
                          {/*  <div className="bg-blue-500">01</div>*/}
                          {/*  <div className="bg-red-500">09</div>*/}
                          {/*</div>*/}
                          <div className="flex items-left justify-between">
                            <span className="text-left w-11/12">
                              {item.illness}
                            </span>
                            {item.edit && item.edit === true ? (
                              <button className="w-1/12">
                                <img
                                  src="/static/save-button.svg"
                                  alt="edit-button"
                                  className="h-8 w-8 text-white rounded-md"
                                  onClick={() => {
                                    let newMainArr =
                                      structuredClone(stateRiskFactorList);
                                    let newArr = newMainArr.data;
                                    newArr[index].edit = !newArr[index].edit;
                                    setStateRiskFactorList({ ...newMainArr });
                                    // setStateRiskFactorList([...newArr]);
                                  }}
                                />
                              </button>
                            ) : (
                              <button className="w-1/12">
                                <img
                                  src="/static/edit-button.svg"
                                  alt="edit-button"
                                  className="h-8 w-8 text-white rounded-md"
                                  onClick={() => {
                                    let newMainArr =
                                      structuredClone(stateRiskFactorList);
                                    let newArr = newMainArr.data;
                                    newArr[index].edit = !newArr[index].edit;
                                    setStateRiskFactorList({ ...newMainArr });
                                    // setStateRiskFactorList([...newArr]);
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        </td>
                        <td className="border border-gray-500 p-2 text-center">
                          {item.edit && item.edit === true ? (
                            <input
                              type="text"
                              value={item.points}
                              className="w-16 h-8 p-2 border border-black rounded-md"
                              // className="border border-black p-2"
                              onChange={(e) => {
                                let newMainArr =
                                  structuredClone(stateRiskFactorList);
                                let newArr = newMainArr.data;
                                newArr[index].points = parseFloat(
                                  e.target.value,
                                );
                                setStateRiskFactorList({ ...newMainArr });
                                // setStateRiskFactorList([...newArr]);
                              }}
                            />
                          ) : (
                            item.points
                          )}
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
    </Base>
  );
}
