import { BrowserRouter, Routes, Route } from "react-router-dom";
// import IndexPage from "../pages/Index";
// import DesignPage from "../pages/DesignPage";
import DoctorTools from "../pages/DoctorTools";
import Login from "../pages/Login";
import DoctorToolsWithLeftMenu from "../pages/DoctorToolsWithLeftMenu";
import Results from "../pages/Results";
import DoctorTools_new from "../pages/DoctorTools_new";
import DoctorToolsRiskFactors from "../pages/DoctorToolsRiskFactors";
import DoctorToolsTests from "../pages/DoctorToolsTests";
import TestId from "../pages/TestId";
// import ChartPage from "../pages/ChartPage";
// import CurrentPage from "../pages/CurrentPage";
// import FiltrationPage from "../pages/FiltrationPage";
// import PaginationPage from "../pages/PaginationPage";
// import RegisterNativePage from "../pages/RegisterNativePage";
// import RegisterReduxPage from "../pages/RegisterReduxPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/*<Route path="/" element={<DoctorTools />}></Route>*/}
        <Route path="/auth" element={<Login />}></Route>
        {/*<Route path="/left" element={<DoctorToolsWithLeftMenu />}></Route>*/}
        <Route path="/" element={<Results />}></Route>
        {/*<Route path="/:id" element={<TestId />}></Route>*/}
        <Route path="/symptoms" element={<DoctorTools_new />}></Route>
        <Route
          path="/risk_factors"
          element={<DoctorToolsRiskFactors />}
        ></Route>
        <Route path="/tests" element={<DoctorToolsTests />}></Route>

        {/*other*/}
        {/*<Route path="*" element={<Login />}></Route>*/}

        {/*TODO Можно сделать здесь 404 страницу*/}
        <Route path="*" element={<Results />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
